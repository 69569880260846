import * as React from "react"
import styles from "./OrderMenu.module.css";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from "react-i18next";
import {Nutrient} from "../../modules/order/OrderTypes";
import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'whitesmoke',
    },
}));

interface NutrientDialogProps {
    handleCloseNutrients: () => void
    openNutrients: boolean
    sortedSelectedFoodItemsNutrients: Nutrient[]
    incompleteNutrients: boolean
}
const NutrientDialog:React.FC<NutrientDialogProps> = ({handleCloseNutrients, openNutrients, sortedSelectedFoodItemsNutrients, incompleteNutrients}) => {
    const {t} = useTranslation()

    return (
        <Dialog classes={{ paper: styles.dialog }} onClose={handleCloseNutrients} aria-labelledby="nutrient-dialog" open={openNutrients}>
            <Card sx={{ overflow: "auto", height: "auto" }}>
            <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {t("nutrient.Titel")}
                    </Typography>
                {incompleteNutrients ?
                    <Typography gutterBottom variant="subtitle2" component="h2" color="primary" sx={{ fontStyle: "italic" }}>
                        {t("nutrient.Onvolledig")}
                    </Typography> : <Typography />
                }
                <Grid item xs={12}>
                        {sortedSelectedFoodItemsNutrients && sortedSelectedFoodItemsNutrients.length > 0 ?
                            <Table size="small" sx={{ borderColor: "lightgrey", borderStyle: "solid", borderWidth: "thin", borderCollapse: "unset" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ pt: 0, pb: 0 }}><Typography sx={{ fontWeight: '600' }} variant="body2" color="textSecondary">{t('allergeen.Omschrijving')}</Typography></TableCell>
                                        {/*<TableCell align="right" sx={{ pt: 0, pb: 0 }}><Typography sx={{ fontWeight: '600' }} variant="body2" color="textSecondary">100g</Typography></TableCell>*/}
                                        <TableCell align="right" sx={{ pt: 0, pb: 0 }}><Typography sx={{ fontWeight: '600' }} variant="body2" color="textSecondary">{t('order.Ukeuze')}</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedSelectedFoodItemsNutrients.map((nutrient) => (

                                        <StyledTableRow>
                                            <TableCell align="left" sx={{ borderBottom: "none", pt: 0, pb: 0 }}>
                                                <Typography variant="body2" color="textSecondary">{nutrient.name}</Typography>
                                            </TableCell>
                                            {/*<TableCell align="right" sx={{ borderBottom: "none", pt: 0, pb: 0 }}>*/}
                                            {/*    <Typography variant="body2" color="textSecondary">{nutrient.value.toFixed(2) + " " + nutrient.unit.substr(0, 2) + "."}</Typography>*/}
                                            {/*</TableCell>*/}
                                            <TableCell align="right" sx={{ borderBottom: "none", pt: 0, pb: 0 }}>
                                                <Typography variant="body2" color="textSecondary">{nutrient.totCalculatedValue.toFixed(2) + " " + nutrient.unit.substr(0,2) + "."}</Typography>
                                            </TableCell>
                                        </StyledTableRow>

                                    ))}
                                </TableBody>
                            </Table> : null}
                    </Grid>
                </CardContent>

                <CardActions>
                    <Button className={styles.foodDialogClose} size="small" color="primary" onClick={handleCloseNutrients}>
                        {t("algemeen.Sluit")}
                    </Button>
                </CardActions>
            </Card>
        </Dialog >
    )
}

export default NutrientDialog