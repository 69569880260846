import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import styles from "./MealMoment.module.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import * as React from "react";
import { DialogActions, DialogContent, Paper, Typography } from "@mui/material";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

interface DisclaimerDialogProps {
    openDisclaimerDialog: boolean
    handleClose: (accept: boolean) => void
}
const DisclaimerDialog: React.FC<DisclaimerDialogProps> = ({ openDisclaimerDialog, handleClose }) => {
    const { t } = useTranslation();
    const profile = useSelector((state: ApplicationState) => state.profile)
    const patient = profile.patient;
    const content = patient.disclaimers.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()) === undefined ? t("profiel.Disclaimer1") + " " + t("profiel.Disclaimer2") : patient.disclaimers.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).disclaimer

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    const handleCloseDisclaimer = () => {        
        handleClose(false);
    }
    const handleAcceptDisclaimer = () => {        
        handleClose(true);
    }

    return <Dialog TransitionComponent={Transition} classes={{ paper: styles.dialog }} aria-labelledby="alert-dialog-title" open={openDisclaimerDialog} onClose={handleCloseDisclaimer} >
        <DialogContent>
            <Typography gutterBottom variant="h5" component="h2">DISCLAIMER</Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDisclaimer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
            </IconButton>
            <Paper elevation={0} square sx={{ p: '2em' }}>
                    <Typography sx={{ textAlign: 'middle' }}>{content}</Typography>
                </Paper>
            </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleAcceptDisclaimer} variant="outlined">
                {t("profiel.Disclaimer")}
            </Button>
            </DialogActions>
    </Dialog>
}

export default DisclaimerDialog