import { Checkbox, FormHelperText, InputLabel, NativeSelect, Paper, SelectChangeEvent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Item from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { saveProfile } from "../../modules/profile/ProfileActions";
import { Diet, Nutrition, Profile } from "../../modules/profile/ProfileTypes";
import styles from "./Profile.module.css";
import { ProfileAvatar } from "./ProfileAvatar";
import Grid from '@mui/material/Grid';

interface RenderProfileProps {
    profile: Profile
    featureShowNutrition: boolean
    featureChangeNutrition: boolean
    allNutritions: Nutrition[]
    featureChangeDiet: boolean    
    allDiets: Diet[]
    patientProfile: Profile
    activeProfile: Profile
}
const RenderProfile: React.FC<RenderProfileProps> = ({ profile, featureShowNutrition, featureChangeNutrition, allNutritions, featureChangeDiet, allDiets, patientProfile, activeProfile}) => {
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();

    let isUsingApp;
    let isAskingConsult;
    // let languagePreference;
    let nutritionPreference;
    let dietPreference;
    let canOrderMeals = profile.canOrderMeals === undefined ? true : profile.canOrderMeals;

    const saveCurrentProfile = (patientNumber: string, isDisclaimerChecked: boolean, preferredNutritionNumber: number, preferredDietNumber: number, preferredLanguage: number, isUsingApp: boolean, isAskingConsult: boolean, newCulture: string, profile: Profile) => {
        saveProfile(patientNumber, isDisclaimerChecked, preferredNutritionNumber, preferredDietNumber, preferredLanguage, isUsingApp, isAskingConsult, newCulture, profile)(dispatch)
    }

    const handleIsUsingAppChange = (event: React.ChangeEvent<HTMLInputElement>, profile: Profile) => {
        const isUsingApp = event.target.checked as boolean;

        saveCurrentProfile(profile.id, true, profile.preferredNutrition, profile.preferredDiet, profile.preferredLanguage, !isUsingApp, profile.isAskingConsult, profile.preferredLanguageCulture, activeProfile);
    }

    const handleIsAskingConsultChange = (event: React.ChangeEvent<HTMLInputElement>, profile: Profile) => {
        const isAskingConsult = event.target.checked as boolean;

        saveCurrentProfile(profile.id, true, profile.preferredNutrition, profile.preferredDiet, profile.preferredLanguage, !profile.isUsingApp, isAskingConsult, profile.preferredLanguageCulture, activeProfile);
    }

    const handleNutritionChange = (event: SelectChangeEvent<number>, profile: Profile) => {
        const selectedNutrition = event.target.value as number;
        const selectedProfileDiet = profile.diet;
        let selectedPreferredDiet = allDiets ? allDiets.find(d => d.id === profile.preferredDiet) : undefined;
        let isValidCombination = selectedPreferredDiet ? selectedPreferredDiet.nutritions.findIndex(n => n === selectedNutrition) : -1;
        if (featureChangeDiet && isValidCombination < 0) {
            profile.preferredNutrition = selectedNutrition;
            profile.preferredDiet = 0;
        }
        saveCurrentProfile(profile.id, true, selectedNutrition, featureChangeDiet ? selectedPreferredDiet ? selectedPreferredDiet.id : 0 : selectedProfileDiet, profile.preferredLanguage, !profile.isUsingApp, profile.isAskingConsult, profile.preferredLanguageCulture, activeProfile);        
    }

    const handleDietChange = (event: SelectChangeEvent<number>, profile: Profile) => {

        const diet = event.target.value as number;
        let nutritionToSave = featureChangeNutrition ? profile.preferredNutrition : profile.nutrition;
        saveCurrentProfile(profile.id, true, nutritionToSave, diet, profile.preferredLanguage, !profile.isUsingApp, profile.isAskingConsult, profile.preferredLanguageCulture, activeProfile);
    }

    // TODO : translation label 'IsUsingApp -> {i18n.t("profiel.UseApp")} ! Dit werkt bij testen in development MAAR werkt NIET na build en release op Azure !!
    // dan staat er gewoon 'profiel.UseApp' ?! Werkt ook niet met this.props.t("profiel.UseApp")
    // misschien omdat het de laatste lijn is in de translation.json in de sectie 'profiel' ?
    // misschien omwile van checkbox?
    // <Typography> + <Ckeckbox> werkt wel maar dan is de tekst gecentreerd in het midden (zie checkbox van 'disclaimer')
    //this.props.isUsingAppTxt

    if (profile.isPrimary) {
        if (profile.isUsingAppTxt != null) {
            isUsingApp = <SettingsListBoxItemComponent label={profile.isUsingAppTxt}>
                <Checkbox
                    disabled={!profile.disclaimerAccepted || !canOrderMeals}
                    checked={profile.isUsingApp}
                    color="primary"
                    onChange={(e) => handleIsUsingAppChange(e, profile)}
                />
            </SettingsListBoxItemComponent>;
        }
        if (profile.isAskingConsultTxt != null) {
            isAskingConsult = <SettingsListBoxItemComponent label={profile.isAskingConsultTxt}>
                <Checkbox
                    disabled={!profile.disclaimerAccepted || !canOrderMeals}
                    checked={profile.isAskingConsult}
                    color="primary"
                    onChange={(e) => handleIsAskingConsultChange(e, profile)}                    
                />
            </SettingsListBoxItemComponent>;
        }
    }

    //const activeNutrition = featureShowNutrition ? <SettingsListBoxItemComponent label={t("profiel.ToeVdgw")}>
    //    <Typography align='left'><strong>{profile.actualNutritionNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name}</strong></Typography>
    //</SettingsListBoxItemComponent> : null; 

    //const activeNutrition = featureShowNutrition ? <SettingsListBoxItemComponent label={t("profiel.ToeVdgw")}>
    //    <Typography align='left'>
    //        <strong>{
    //            profile.actualNutritionNames.findIndex(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()) < 0 ?
    //                profile.actualNutritionNames.find(x => x.culture.toLowerCase().trim() === "nl").name : 
    //                profile.actualNutritionNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name
    //        }
    //        </strong>
    //    </Typography>
    //</SettingsListBoxItemComponent> : null; 

    const activeNutrition = featureShowNutrition ? <Grid>
        <Grid item xs={4} md={4} sx={{ textAlign: 'left', paddingLeft: '16px', paddingTop:'8px' }} >
            <Typography><Item>{t("profiel.ToeVdgw")}</Item></Typography>
        </Grid>
        {/*<Typography align='left'>*/}
        <Grid item xs={8} md={8} sx={{ textAlign: 'left', paddingLeft:'30px' }} >
            <strong>{
                profile.actualNutritionNames.findIndex(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()) < 0 ?
                    profile.actualNutritionNames.find(x => x.culture.toLowerCase().trim() === "nl").name :
                    profile.actualNutritionNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name
            }
            </strong>
        </Grid>
        {/*</Typography>*/}        
    </Grid> : null; 

    if (profile.newPatientAdaptDiet && featureChangeNutrition && allNutritions && patientProfile.disclaimerAccepted && canOrderMeals) {
        nutritionPreference = <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1, 1fr)' }}>
            {/*<Item>*/}
            {/*    <SettingsListBoxItemComponent label={t("profiel.VoorVdgw")}> </SettingsListBoxItemComponent>*/}
            {/*</Item>*/}
            <Item sx={{ textAlign: 'left', paddingTop: '20px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
                <FormControl variant="outlined" fullWidth >
                    {/*<Select sx={{ minWidth: '10vw', maxWidth: '50vw' }}*/}
                    <InputLabel sx={{ fontWeight: 500 }} id="labelNutrition">{t("profiel.VoorVdgw")}</InputLabel>
                    <Select sx={{ paddingTop: '5px', paddingBottom: '5px' }}
                        labelId="labelNutrition"
                        label={t("profiel.VoorVdgw")}
                        value={profile.preferredNutrition ? profile.preferredNutrition : ''}
                        onChange={(e) => handleNutritionChange(e, profile)}>
                        {
                            allNutritions.map((value, index) =>
                                <MenuItem className={styles.dietItem} key={index}
                                    value={value.id}>{value.name.toLowerCase()}</MenuItem>
                            )
                        }                        
                    </Select>                    
                </FormControl>
            </Item>
        </Box>
    }

    //const activeDiet = <SettingsListBoxItemComponent label={t("profiel.ToeDieet")}>
    //    <Typography align='left'><strong>{profile.actualDietNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name}</strong></Typography>
    //</SettingsListBoxItemComponent>;

    //const activeDiet = <SettingsListBoxItemComponent label={t("profiel.ToeDieet")}>
    //    <Typography align='left'>
    //        <strong>
    //            {profile.actualDietNames.findIndex(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()) < 0 ?
    //                profile.actualDietNames.find(x => x.culture.toLowerCase().trim() === "nl").name :
    //                profile.actualDietNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name
    //            }
    //        </strong>
    //    </Typography>
    //</SettingsListBoxItemComponent>;

    const activeDiet = <Grid>
        <Grid item xs={4} md={4} sx={{ textAlign: 'left', paddingLeft: '16px', paddingTop: '8px' }} >
            <Typography><Item>{t("profiel.ToeDieet")}</Item></Typography>
        </Grid>
        {/*<Typography align='left'>*/}
        <Grid item xs={8} md={8} sx={{ textAlign: 'left', paddingLeft: '30px' }} >
            <strong>
                {profile.actualDietNames.findIndex(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()) < 0 ?
                    profile.actualDietNames.find(x => x.culture.toLowerCase().trim() === "nl").name :
                    profile.actualDietNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name
                }
            </strong>
        </Grid>
        {/*</Typography>*/}
    </Grid>;

    //31/08/2022 : Hide preferredLanguage in Profile
    //if (this.props.featureTranslationActive && profile.isPrimary && this.props.allLanguages) {
    //    languagePreference = <SettingsListBoxItemComponent label={this.props.t("profiel.PrefLanguage")}>
    //        <FormControl>
    //            <Select disabled={!profile.disclaimerAccepted} style={{ minWidth: '10vw', maxWidth: '50vw' }} value={profile.preferredLanguage ? profile.preferredLanguage : ''}
    //                onChange={(e) => this.handleLanguageChange(e, profile)}>
    //                {
    //                    this.props.allLanguages.map((value, index) =>
    //                        <MenuItem className={styles.languageItem} key={index} value={value.id}>{value.name}</MenuItem>
    //                    )
    //                }
    //            </Select>
    //        </FormControl>
    //    </SettingsListBoxItemComponent>
    //}

    //08/02/2021 : ... Not (!profile.isPrimary && this.props.featureChangeDiet && this.props.allDiets && this.props.patientProfile.disclaimerAccepted) || (profile.isPrimary && ..
    //Same for Primary patient as for guests
    if (profile.newPatientAdaptDiet && featureChangeDiet && allDiets && patientProfile.disclaimerAccepted && canOrderMeals) {
        //11/04/2024 : If nutrition is not changeable then check diets against profile.Nutrition not against profile.preferredNutrition
        let nutritionToCheckAgainst = featureChangeNutrition ? profile.preferredNutrition : profile.nutrition;
        let allowedDiets = allDiets.filter(d => d.nutritions.find(n => n === nutritionToCheckAgainst));

        dietPreference = <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1, 1fr)' }}>
            {/*<Item>*/}
            {/*    <SettingsListBoxItemComponent label={t("profiel.VoorDieet")}></SettingsListBoxItemComponent>*/}
            {/*</Item>*/}
            <Item sx={{ textAlign: 'left', paddingTop: '20px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
                <FormControl variant="outlined" fullWidth >
                    <InputLabel sx={{ fontWeight: 500 }} id="labelDiet">{t("profiel.VoorDieet")}</InputLabel>                
                    {/*<Select sx={{minWidth: '10vw', maxWidth: '50vw'}}*/}
                    <Select sx={{ paddingTop: '5px', paddingBottom: '5px' }}
                        labelId="labelDiet"
                        label={t("profiel.VoorDieet")}
                        value={profile.preferredDiet ? profile.preferredDiet : ''}
                        onChange={(e) => handleDietChange(e, profile)}>
                        {
                            allowedDiets.map((value, index) =>                                
                                <MenuItem className={styles.dietItem} key={index}
                                    value={value.id}>{value.nutritions.findIndex(n => n === profile.preferredNutrition) > -1 ? value.name.toLowerCase() : value.name}</MenuItem>
                            )
                        }
                    </Select>                    
                </FormControl>
            </Item>
        </Box>
    }

    return <Paper square elevation={1}>
        <List>
            <ListItem key={profile.id} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Box display={'flex'}>
                    <ListItemAvatar>
                        <ProfileAvatar profile={profile}/>
                    </ListItemAvatar>
                    <ListItemText primary={profile.name} sx={{alignSelf: 'center'}}/>
                </Box>
            </ListItem>
            <Box sx={{ml: '2em', mr: '1em'}}>
                {isUsingApp}
                {isAskingConsult}
                {activeNutrition}
                {nutritionPreference}
                {activeDiet}
                {/*{languagePreference}*/}                
                {dietPreference}
            </Box>

        </List>
    </Paper>
}

export default RenderProfile

interface SettingsListBoxItemProps {
    label: string;
}

const SettingsListBoxItemComponent: React.FC<SettingsListBoxItemProps & PropsWithChildren> = ({children, label}) => {
    return <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography align='left'>{label}</Typography>  
        <Box sx={{ml: '2em'}}>
            {children}
        </Box>
    </ListItem>
}

