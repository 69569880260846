import * as React from "react";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import styles from "./ErrorComponent.module.css";
import { useTranslation } from 'react-i18next';
import { colors } from "@mui/material";

export const ErrorComponent = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{
            height: 300,
            width: 300,
            borderRadius: "50%",
            bgcolor: "#f2f5f8",
            transform: "scale(1.2)",
            marginTop: "50%"
        }}>
            <Box sx={{ paddingTop: "20%" }} component={'img'} src={process.env.PUBLIC_URL + 'error.png'} alt="307 temporary redirect" />
            <Typography className={styles.errorTxt} >
                {t("error.ErrorGeenMlt")}
            </Typography>
        </Box>        
    )
}