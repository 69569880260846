import { ConfigState, initialConfigState } from "./ConfigTypes";
import { configAction } from "./ConfigActions";

export const configReducer = (state: ConfigState = initialConfigState, action: any): ConfigState => {
    switch (action.type) {
        case configAction.RECEIVE_CONFIG:
            return handleReceiveConfig(state, action.payload);
        default: 
            return state;
    }
}

const handleReceiveConfig = (state: ConfigState, payload: any): ConfigState => {
    return {
        authentication: payload.authentication,
        appOffline: false,
        showNutrition: payload.showNutrition,
        changeNutrition: payload.changeNutrition,
        changeDiet: payload.changeDiet,
        showDiet: payload.showDiet,
        mealPictures: payload.mealPictures,
        guestEaters: payload.guestEaters,
        helpTipAvailable: payload.helpTipAvailable,
        loadDataProfiles: payload.loadDataProfiles,
        translationActive: payload.translationActive,
        languages: payload.languages
    }
}

