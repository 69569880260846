import * as React from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router";
import styles from "./components/MasterContainer.module.css";
import {ClientSessionProvider} from './modules/auth/ClientSessionProvider';
import {ErrorSnackbarComponent} from "./components/error/ErrorSnackbarComponent";
import {PropsWithChildren} from "react";
import LoginComponent from "./components/auth/LoginComponent";
import HeaderComponent from "./components/header/HeaderComponent";
import {HomeComponent} from "./components/home/HomeComponent";
import MealMomentComponent from "./components/order/mealMoment/MealMomentComponent";
import OrderComponent from "./components/order/OrderComponent";
import ProfilePageComponent from "./components/profile/ProfilePageComponent";
import { ErrorComponent } from "./components/error/ErrorComponent";
import { Error404Component } from "./components/error/Error404Component";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    RouteProps,
} from "react-router-dom";
import {ApplicationState} from "./store";
import {closeSnackbar} from "./modules/order/OrderActions";
import Box from "@mui/material/Box";

const ComponentWrapper: React.FC<PropsWithChildren> = ({children}) => {
    const dispatch = useDispatch()
    const snackbar =useSelector((state: ApplicationState) => state.order.snackbar)
    const handleClose = () => {
        dispatch(closeSnackbar());
    }
    return (<>
        <HeaderComponent/>
        <ErrorSnackbarComponent snackbar={snackbar} closeSnackbar={handleClose} />
        <Box className={styles.container}>
        {children}
        </Box>
    </>)
}

interface PrivateRouteReduxProps {
    isAuthenticated: boolean;
    isLoading: boolean;
}

interface PrivateRouteReduxDispatchProps {
    preAuthenticateUser(): void;
}

const PrivateRouteComponent: React.FC<PrivateRouteReduxProps & PrivateRouteReduxDispatchProps & PropsWithChildren> = ({
                                                                                                                          children,
                                                                                                                          isAuthenticated,
                                                                                                                      }) => {
    return (
        <ClientSessionProvider>
            {isAuthenticated ? <>{children}</> : <Navigate to="/account/login"/>}
        </ClientSessionProvider>
    );
}

let mapState = (state: any): PrivateRouteReduxProps => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isLoading: state.auth.isAuthenticating
    }
};

let mapDispatch = (): PrivateRouteReduxDispatchProps => {
    return {
        preAuthenticateUser: () => null
    };
}

const PrivateRoute = connect<PrivateRouteReduxProps, PrivateRouteReduxDispatchProps>(mapState, mapDispatch)(PrivateRouteComponent) as unknown as React.ComponentClass<RouteProps, any>;


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
        <Route
            path="/"
            element={
                <PrivateRoute>
                    <ComponentWrapper><HomeComponent/></ComponentWrapper>
                </PrivateRoute>
            }
        />

            <Route
                path="/menu"
                element={
                    <PrivateRoute>
                        <ComponentWrapper> <MealMomentComponent/></ComponentWrapper>
                    </PrivateRoute>
                }
            />

            <Route
                path="/order"
                element={
                    <PrivateRoute>
                        <ComponentWrapper><OrderComponent/></ComponentWrapper>
                    </PrivateRoute>
                }
            />

            <Route
                path="/profile"
                element={
                    <PrivateRoute>
                        <ComponentWrapper><ProfilePageComponent/></ComponentWrapper>
                    </PrivateRoute>
                }
            />

            <Route
                path="/307"
                element={
                    <PrivateRoute>                        
                        <ComponentWrapper><Box sx={{ textAlign: "-webkit-center" }}><ErrorComponent /></Box></ComponentWrapper>                        
                    </PrivateRoute>
                }
            />

            <Route
                path="/404"
                element={
                    <PrivateRoute>
                        <ComponentWrapper><Error404Component/></ComponentWrapper>
                    </PrivateRoute>
                }
            />

            <Route path="/account/login" element={<ComponentWrapper><LoginComponent/></ComponentWrapper>}/>
        </>
    )
);
export const RoutesComponent: React.FC = () => {
    return (
            <RouterProvider router={router}/>
    );
}
