import * as React from "react";
import {Profile} from "../../modules/profile/ProfileTypes";
import {SelectProfileComponent} from "../profile/SelectProfileComponent";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import {ApplicationState} from "../../store";
import {getMealDays} from "../../modules/menu/MenuActions";
import {getLanguages} from "../../modules/profile/ProfileActions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export const HomeComponent: React.FC= () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()

    const profileState = useSelector((state: ApplicationState) => state.profile)
    const hasMultipleProfiles = profileState.profiles.length > 1
    const patient = profileState.patient
    const guests = profileState.guests

    const [redirectOnAction, setRedirectOnAction] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const selectProfile = (profile: Profile, redirect?: boolean) => {
        getMealDays(profile,dispatch).then((e)=>{
            if (e === 1 && redirect) {
                profile.canOrderMeals = true;                
                navigate('/menu');
            }
            if (e === -1) {
                profile.canOrderMeals = false;               
                navigate('/307');
            }
            setLoading(false)
        })
        getLanguages()(dispatch)
    }

    React.useEffect(() => {
        i18n.changeLanguage(patient.preferredLanguageCulture);

       selectProfile(patient, false);
        //16/12/2022 : Always fill the selectedProfile because if the popup appears with multiple profile one can click
        // on the 'hamburger-menu' and select 'Profile' -> if you then click on the 'back' arrow the page won't load because
        // the selectedProfile is empty !!
        //if (!this.props.hasMultipleProfiles) {
        //    this.props.selectProfile(this.props.patient);
        //}

        setRedirectOnAction(false)
    }, [patient, hasMultipleProfiles])

    const handleSelectProfile = (profile: Profile) => {
        setLoading(true)
        selectProfile(profile, true);
    }

    React.useEffect(() => {
        if (!hasMultipleProfiles || redirectOnAction) {
            patient.canOrderMeals = true; 
            navigate('/menu');
        }
    }, [hasMultipleProfiles, redirectOnAction, patient])

    if (loading) return <CircularProgress sx={{mt: '25px'}}/>;

    return (<Box sx={{ m: '25px' }}>
        <Typography variant='h5' component='h3' sx={{ fontSize: '1.17em', fontWeight: 'bold' }}>{t("home.Wie")}</Typography>
        <SelectProfileComponent
            patient={patient}
            guests={guests}
            onSelectProfile={handleSelectProfile} />
    </Box>)    
}